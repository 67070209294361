import React, { useRef, useState, useEffect } from 'react'

import Heading from '../../../components/Heading'
import Button from '../../../components/Button'

import * as Styled from './styled'
import getStaticImage from '../../../hooks/getStaticImage'

const SectionHero = ({ toggle }: SectionHeroProps): JSX.Element => {
  const buttonRef = useRef() as any
  const [sticky, setSticky] = useState<boolean>(false)

  const onScroll = () => {
    const offsetTopElement = buttonRef?.current?.offsetTop

    if (window.pageYOffset >= offsetTopElement) {
      setSticky(true)
    } else if (window.pageYOffset < offsetTopElement) {
      setSticky(false)
    }
  }

  useEffect(() => {
    if (window.innerWidth > 520) { return }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [])

  return (
    <Styled.Section>
      <div className="container">
        <Styled.LeftSide>
          <Styled.HighlightTitle>O cartão PJ da sua conta Cora</Styled.HighlightTitle>
          <Heading headingLevel="h2" size="xlarge">Prático, moderno e sem anuidade.</Heading>
          <Styled.Text className="s-text-seo">Um cartão para empresas criado para atender às necessidades de quem empreende, desde as despesas simples do dia a dia até compras internacionais.</Styled.Text>
          <Styled.ButtonWrapper ref={buttonRef} className={sticky ? 'sticky' : ''}>
            <Button handleClick={toggle} size="large" mobileShadow={sticky ? true : false}>Quero um Cartão PJ</Button>
          </Styled.ButtonWrapper>
        </Styled.LeftSide>
        <Styled.RightSide>
          <div>
            <img src={getStaticImage('hero-desktop.png')} style={{ width: '100%' }} />
          </div>
        </Styled.RightSide>
      </div>
    </Styled.Section>
  )
}

export default SectionHero

export const aboutInfos = [
  {
    title: 'Cartão de crédito PJ Visa',
    description:
      'Segurança e conveniência em seus pagamentos. Seu cartão PJ Cora tem a bandeira Visa, aceito em milhões de estabelecimentos comerciais e já vem com a tecnologia contactless que possibilita o pagamento por aproximação.',
    image: 'card-feature-1.png',
  },
  {
    title: 'Múltiplos cartões virtuais para compras na Internet',
    description:
      'Crie diversos cartões PJ virtuais para diferentes serviços ou áreas da sua empresa. Aceito internacionalmente, ideal para pagamentos online de serviços, como anúncios e assinaturas de software.',
    image: 'card-feature-2.png',
  },
  {
    title: 'Cartões físicos para você e seus sócios',
    description:
      'Transparência e facilidade para identificar despesas, com o extrato individualizado de cada cartão. Isso ajuda a garantir mais controle de custos e melhor gerenciamento do fluxo de caixa.',
    image: 'card-feature-3.png',
  },
  {
    title: 'Saques nos caixas da rede Banco24Horas',
    description:
      'Use seu cartão Cora para realizar saques da sua conta em caixas eletrônicos. São mais de 23 mil caixas em 680 municípios do Brasil. Cobramos uma tarifa de R$ 9,90 por cada saque realizado no Débito Cora.',
    image: 'card-feature-4.png',
  },
]
